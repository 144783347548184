<template>
  <div class="main">
    <div class="main_box">
      <div class="main_form">
        <div class="main_form_list">
          <div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">材料分类：</div>
            <div class="main_form_list_item_position">
              <div class="flex main_form_list_item_center">
                <div class="flex main_form_list_item_center_margin">
                  <div :class="item === districtItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in districtList" :key="index" @click="onTime(item)">
                    <span>{{item}}</span>
                  </div>
                </div>
              </div>
              <!--<div class="flex main_form_list_item_more">
                <div class="main_form_list_item_more_subset" v-for="(items, indexs) in 10" :key="indexs">
                  <span>123</span>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="flex_center main_pdf">
        <h2 class="main_pdf_title">{{detailsData.journalName}}</h2>
        <div class="flex_center main_pdf_list">
          <!--<div class="main_pdf_list_item">
            <el-link type="primary">在线查看</el-link>
          </div>-->
          <div class="main_pdf_list_item" v-if="detailsData.url || detailsData.journalUrl">
            <el-link type="primary" :href="detailsData.url || detailsData.journalUrl" target="_blank">下载原文档</el-link>
          </div>
          <div class="main_pdf_list_item">
            <span>更新时间：{{detailsData.updateDate || '-'}}</span>
          </div>
        </div>
      </div>
      <div class="flex main_input">
        <div class="flex main_input_l">
          <div class="main_input_l_item">
            <el-input v-model="name" placeholder="输入名称"></el-input>
          </div>
          <div class="main_input_l_item">
            <el-input v-model="specs" placeholder="输入规格型号及特征"></el-input>
          </div>
          <el-button type="primary" @click="getSearch">搜 索</el-button>
        </div>
        <div class="main_input_r">
          <el-button size="mini" :icon="isCollect ? 'el-icon-star-on' : 'el-icon-star-off'" class="main_operation_item" :style="isCollect ? 'color: #ffc94b;' : ''" @click="getCommonCollectionAddOrDel">收藏</el-button>
        </div>
      </div>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%">
        <!--<el-table-column
          prop="date"
          label="编号"
          width="180">
        </el-table-column>-->
        <el-table-column
          prop="name"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="specs"
          label="规格型号及特征">
        </el-table-column>
        <el-table-column
          prop="minPrice"
          label="除税价">
        </el-table-column>
        <el-table-column
          prop="minTaxPrice"
          label="含税价">
        </el-table-column>
        <el-table-column
          prop="unit"
          label="单位">
        </el-table-column>
        <el-table-column
          prop="tongbi"
          label="同比">
        </el-table-column>
        <el-table-column
          prop="huanbi"
          label="环比">
        </el-table-column>
        <el-table-column
          label="走势">
          <template slot-scope="scope">
            <i class="el-icon-data-line" style="font-size: 24px; color: #007EF2; cursor: pointer;" @click="onLink(scope.row)"></i>
          </template>
        </el-table-column>
        <!--<el-table-column
          prop="address"
          label="备注">
        </el-table-column>-->
      </el-table>
      <div class="flex main_pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="size"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import cache from "@/libs/util.cache.js";
  import { urlProjectPriceInfoEsGetType34, urlProjectPriceInfoEsMaterialPriceInfo, urlCommonCollectionCheck, urlCommonCollectionAddOrDel } from '@/api/engineering'
  export default {
    name: "infoPricePeriodical",
    data() {
      return {
        districtList: [],
        districtItem: '',
        tableData: [],
        currentPage: 1,
        size: 20,
        total: 0,
        detailsData: {},
        isCollect: false,
        loading: false,
        name: '',
        specs: ''
      }
    },
    mounted() {
      this.detailsData = cache.getSessionStorage("infoPrice")
      this.getProjectPriceInfoEsGetType34()
      this.getProjectPriceInfoEsMaterialPriceInfo()
      this.getCommonCollectionCheck()
    },
    methods: {
      getSearch() {
        this.currentPage = 1
        this.getProjectPriceInfoEsMaterialPriceInfo()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getProjectPriceInfoEsMaterialPriceInfo()
      },
      async getCommonCollectionAddOrDel() {
        const param = {
          bid: this.detailsData.uuid,
          type: "projectPriceInfo"
        }
        const res = await urlCommonCollectionAddOrDel(param)
        this.isCollect = !this.isCollect
      },
      async getCommonCollectionCheck() {
        const param = {
          bid: this.detailsData.uuid,
          type: "projectPriceInfo"
        }
        const res = await urlCommonCollectionCheck(param)
        this.isCollect = res.data
      },
      async getProjectPriceInfoEsMaterialPriceInfo() {
        this.loading = true
        const param = {
          current: this.currentPage,
          journalUuid: this.detailsData.uuid,
          size: this.size,
          type3: this.districtItem,
          name: this.name,
          specs: this.specs
        }
        const res = await urlProjectPriceInfoEsMaterialPriceInfo(param)
        const { records, size, total } = res.data
        this.tableData = records
        this.size = size
        this.total = total
        this.loading = false
      },
      onLink(e) {
        this.$router.push({path: "details", query: { id: e.id, uuid: e.uuid }});
      },
      onTime(e) {
        if (e === this.districtItem) {
          this.districtItem = ''
          this.getProjectPriceInfoEsMaterialPriceInfo()
          return
        }
        this.districtItem = e
        this.getProjectPriceInfoEsMaterialPriceInfo()
      },
      async getProjectPriceInfoEsGetType34() {
        const param = {
          province: this.detailsData.province
        }
        const res = await urlProjectPriceInfoEsGetType34(param)
        this.districtList = res.data.type3List
      }
    }
  }
</script>

<style scoped lang="less">
  .main {
    text-align: left;
    .main_box {
      .main_form {
        padding: 20px;
        margin-bottom: 20px;
        border: 1px solid #E4EAF2;
        .main_form_list {
          .main_form_list_item {
            align-items: flex-start;
            padding-bottom: 20px;
            .main_form_list_item_title {
              padding-right: 10px;
              font-size: 16px;
              line-height: 32px;
              color: #4E4E4E;
            }
            .main_form_list_item_position {
              flex: 1;
              .main_form_list_item_center {
                flex: 1;
                /deep/ .el-cascader, /deep/.el-select {
                  width: 100%;
                }
                .main_form_list_item_center_margin {
                  flex-wrap: wrap;
                  margin: 0 -11px;
                  .main_form_sort_item {
                    height: 32px;
                    line-height: 32px;
                    padding: 0 8px;
                    margin: 0 11px;
                    cursor: pointer;
                  }
                  .main_form_sort_item_on {
                    background-color: #EDF4FF;
                    border-radius: 4px;
                    color: #1890FF;
                  }
                  .main_form_list_item_center_margin_item {
                    height: 32px;
                    line-height: 32px;
                    padding: 0 8px;
                    margin: 5px 13px;
                    cursor: pointer;
                    background-color: #F6F7FB;
                    color: #4E4E4E;
                  }
                  .main_form_list_item_center_margin_item:hover {
                    background-color: #EDF4FF;
                  }
                }
              }
              .main_form_list_item_more {
                margin-top: 12px;
                flex-wrap: wrap;
                background-color: #F8F8F8;
                padding: 5px;
                .main_form_list_item_more_subset {
                  padding: 15px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .main_pdf {
        padding-bottom: 20px;
        justify-content: space-between;
        .main_pdf_title {
          font-size: 24px;
          font-weight: bold;
          color: #212121;
        }
        .main_pdf_list {
          margin: 0 -10px;
          .main_pdf_list_item {
            padding: 0 10px;
            > span {
              font-size: 16px;
              color: #4E4E4E;
            }
          }
        }
      }
      .main_input {
        padding-bottom: 20px;
        justify-content: space-between;
        .main_input_l {
          .main_input_l_item {
            padding-right: 20px;
          }
        }
      }
      .main_pagination {
        justify-content: flex-end;
        padding-top: 20px;
      }
    }
  }
</style>
